<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less">
body {
  margin: 0;
}
.app-container {
  padding: 20px;
}
.pageborder{
  overflow: hidden; // 为背景色需要，进行了溢出隐藏
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
}
.pageback{
  margin: 12px;
  background-color: #ffffff;
}
.pageinpadding{
  padding: 16px;
}
</style>