import Vue from 'vue'
import Vuex from 'vuex'
// ls: window.sessionStorage
Vue.use(Vuex)

const state = {
	userInfo: {},
	leaderInfo: [],
	departmentInfo: [],
}

const mutations = {
	saveUserInfo(state, params) {
		state.userInfo = params;
	},
}

const actions = {
	async setUserInfo({ commit }, userInfo) {
		commit('saveUserInfo', userInfo);
	},
}

export default new Vuex.Store({
	state,
	mutations,
	actions,
})
